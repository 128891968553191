import React, { Component } from "react";
import { Row, Col, Alert, Modal, Button } from "react-bootstrap";
import styled from "styled-components";
import Banner from "./../images/hsm-revisions/HSMBackgroundImage.jpg";
import LandingBanner from "./landing-banner";
import LandingCards from "./landing-cards";
import LandingCardsD from "./landing-cards-d";
import ContactForm from "./../contact-form";
import Footer from "./../footer.js";
import ManufacturingImg from "./../images/DSC08550.jpg";

const StyledCookieAlert = styled(Alert)`
  position: fixed;
  bottom: 10px;
  width: 50%;
  left 25%;
  z-index: 200;
  @media(max-width: 400px){
    width: 100%;
    left: 0;
  }
`;

const AlertDismissible = (props) => {
  const alertOverride = localStorage.getItem('hideAlert')

  return (
    <>
        <StyledCookieAlert show={ alertOverride ? !alertOverride : props.showAlert } variant="dark">
        <Alert.Heading>Cookies Usage</Alert.Heading>
        <p>
        This site uses cookies to provide you with a great user experience. By using this website, you accept our <a href="/privacy-policy"> use of cookies</a>
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() =>  props.hideAlert()} variant="outline-dark">
           I accept.
          </Button>
        </div>
      </StyledCookieAlert>

    </>
  );
}

const LandingTop =
  "We are the experts, offering state-of-the-art manufacturing services.";
const LandingBottom =
  "We support a wide range of medical equipment for on-site Field Service Engineering.";


class ManufacturingServices extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      width: window.innerWidth,
     
      showAlert: true,
  }
  
};

hideAlert = () => {
  this.setState({showAlert: !this.state.showAlert})
  localStorage.setItem('hideAlert', true)
}

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };


  renderLandingCards = () => {
    const width = this.state.width;
    const isMobile = width <= 800;
    return isMobile ? <LandingCards /> : <LandingCardsD />;
  };

  render() {
    return (
      <div>
        <div className="bodyContainer bodyContainerLanding">
          <Row>
            <img id="heroimage" src={Banner} alt="circuit board" />
          </Row>

          <Row>
            
          <div className="jumbotron contractManufacturingText">
          <Row>
            <Col sm={12} lg={6}>
              <img
                src={ManufacturingImg}
                className="contractManufacturingImg"
                alt="Manufacturing Technician"
              />
            </Col>
            <Col sm={12} lg={6}>
              <br />
              <p id="contractManufacturing">
              H&S quality manufacturing services is the ultimate single source partnership 
              with the experts and experience you can count on to get your product to your end user. 
              The supreme business relationship for all your manufacturing needs!
                <br />
                <br />
                <a
                  href="https://forms.office.com/Pages/ResponsePage.aspx?id=yZ61zV7aiEKPav757gS-nSlEXTjpGhhEviQPsXQp-EBUMTQxQzM5NE5LT0pRSkg0WDBWUDc0N0tPOS4u&embed=true"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="outline-secondary">
                    Contact Us
                  </Button>
                </a>
              </p>
            </Col>
          </Row>
        </div>
          </Row>
          <br />
          <LandingBanner text={LandingTop} />
          <Row id="LandingCardsRow">{this.renderLandingCards()}</Row>
          <Row>
            <LandingBanner text={LandingBottom} />
          </Row>

          <ContactForm />
        </div>
        

        <Footer />
      
          
        
      </div>
    );
  }
}

export default ManufacturingServices;

import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";

class Footer extends Component {
  render() {
    return (
      <Navbar sticky="bottom" className="bg-light">
        <Navbar.Text id="copyright">
          &copy; 2022 All Rights Reserved
        </Navbar.Text>

        <Navbar className="ml-auto">
          <Nav.Link className="footerNavLink" href="/privacy-policy">
            Privacy Policy
          </Nav.Link>
        </Navbar>
      </Navbar>
    );
  }
}

export default Footer;

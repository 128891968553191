import React, { Component } from "react";
import { Col, Button, Row } from "react-bootstrap";
import RepairServices from "./images/newImages/Depot Electronics.jpg";
import TechSupport from "./images/techSupport.JPG";
import MotorShield from "./images/Genius_614_No_Background_Shadow_Added.png";
import CleaningService from "./images/RevisedImages/CalibrationLabUnstretched.jpg";
import CenturyAlt from "./images/CenturyAlternateCropped.jpg";
import Century from "./images/CenturyPlaceholderImage.png";

class LandingCardsD extends Component {
  render() {
    return (
      <Col lg={12}>
        <Row className="equal">
          <Col md={4} lg={4} className="LandingCardsCol">
            <h5>Hemodialysis&nbsp;Parts&nbsp;Service&nbsp;Center</h5>

            <div className="containingDivLandingD">
              <img
                src={RepairServices}
                className="landingCardDImg"
                alt="Repair Services"
              />
              <i className="arrow up" />

              <div className="cardOverlay">
                <div className="landingCardDText">
                  <br />
                  <p>
                    <span className="hstext">H&S</span> offers a quick
                    turnaround time along with our signature 6-month, hassle
                    free warranty policy for all services.
                  </p>
                  <Button variant="light">
                    <a href="/services">Learn More</a>
                  </Button>
                </div>
              </div>
            </div>
          </Col>

          <Col md={4} lg={4} className="LandingCardsCol">
            <h5>Calibration,&nbsp;Solutions,&nbsp;Reference&nbsp;Meters</h5>

            <div className="containingDivLandingD">
              <img
                src={CleaningService}
                className="landingCardDImg"
                alt="Calibration Solutions"
              />
              <i className="arrow up" />

              <div className="cardOverlay">
                <div className="landingCardDText">
                  <br />
                  <p>
                    With fast turnaround times,{" "}
                    <span className="hstext">H&S</span> is your premiere
                    provider for calibrations, solutions and dialysate meters.
                  </p>
                  <Button variant="light">
                    <a href="/meters-calibration-solutions">Learn More</a>
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4} lg={4} className="LandingCardsCol">
            <h5>
              <span className="hstext">H&S</span>
              &nbsp;Biomedical&nbsp;Technician&nbsp;Assist&nbsp;Devices
            </h5>

            <div className="containingDivLandingD">
              <img
                src={MotorShield}
                className="landingCardDImg"
                alt="H&S Motor Shield"
              />
              <i className="arrow up" />

              <div className="cardOverlay">
                <div className="landingCardDText">
                  <br />
                  <p>
                    <span className="hstext">H&S</span> is dedicated to helping
                    biomed technicians through innovative products such as the
                    Genius 614 Valve & Chamber Analyzer, BTA-612 Selectable
                    Voltage Power Supply, Motor Shields & Valve Repair Kits.
                  </p>
                  <Button variant="light">
                    <a href="/products">Learn More</a>
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="equal">
          <Col md={4} lg={4} className="LandingCardsCol">
            <h5>Technical Support</h5>
            <div className="containingDivLandingD">
              <img
                src={TechSupport}
                className="landingCardDImg"
                alt="Tech Support Team"
              />
              <i className="arrow up" />

              <div className="cardOverlay">
                <div className="landingCardDText">
                  <br />
                  <p>
                    Speak with a highly skilled Technical Support expert to
                    answer all your troubleshooting questions. All calls are
                    answered by our friendly customer service representatives.
                  </p>
                  <Button variant="light">
                    <a href="/technical-support">Learn More</a>
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4} lg={4} className="LandingCardsCol">
            <h5>Field&nbsp;Service&nbsp;Engineering</h5>
            <div className="containingDivLandingD">
              <img
                src={CenturyAlt}
                className="landingCardDImg"
                alt="Field Service Engineering"
              />
              <i className="arrow up" />

              <div className="cardOverlay">
                <div className="landingCardDText">
                  <br />
                  <p>
                    <span className="hstext">H&S</span> performs repairs and
                    annual maintenance on perfusion equipment and other machines
                    used in open heart surgery, servicing hospitals nationwide
                    and internationally. Specialized profusion equipment
                    services include Century, Sorin, Quest and Medtronic. {" "}
                  </p>
                  <Button variant="light">
                    <a href="/field-service-engineering">Learn More</a>
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4} lg={4} className="LandingCardsCol">
            <h5>Manufacturing&nbsp;Services</h5>
            <div className="containingDivLandingD">
              <img
                src={Century}
                className="landingCardDImg"
                alt="Manufacturing"
              />
              <i className="arrow up" />

              <div className="cardOverlay">
                <div className="landingCardDText">
                  <br />
                  <p>
                    Our Manufacturing Service Center provides dedicated and customizable
                    manufacturing services for the global medical community.
                  </p>
                  <Button variant="light">
                    <a href="hsmanufacturing/manufacturing-services">Learn More</a>
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default LandingCardsD;

import React, { Component } from "react";
import { Row, Tabs, Tab, Col, Jumbotron, Button, ListGroup, ListGroupItem } from "react-bootstrap";
import ContactForm from "./../contact-form";
import Footer from "./../footer.js";
import LandingBanner from "./landing-banner";
import Soldering from "./../images/revisions/SolderingCloseUp.jpg";
import scrollToComponent from "react-scroll-to-component";

//content ported from hsmsvc.com

const HeaderText =
  "We bring your vision to life; creating reality from your dreams.";

const EngineeringBanner = "The optimal solution for you.";
class Engineering extends Component {
  render() {
    return (
      <div>
        <div className="bodyContainer">
          <LandingBanner text={HeaderText} />
          <Row>
            <div class="jumbotron">
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <img
                    src={Soldering}
                    className="img-responsive"
                    id="servicesImg"
                    alt="Repair Technician"
                  />
                </Col>
                <Col sm={12} md={6} lg={6} id="servicesheaderp">
                  <div id="spacingDiv">
                    <br />
                    <h1 className="productshead">
                      <span className="HSLogoText nonWhite">H&S</span>{" "}
                      Engineering
                    </h1>
                    <p id="engineeringHeader">
                      Our highly experienced and knowledgeable engineering team
                      allow for a supreme collaboration for your project;
                      regardless of the scale, letting you maintain focus and
                      valuable time on your internal resources. Our engineering
                      offerings are fully customizable to meet the specific
                      needs of your project. We specialize in the
                      commercialization and design transfer of your creation
                      straight into the crucial production phase and final
                      release to market.
                      <br />
                      <br />
                      <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=yZ61zV7aiEKPav757gS-nSlEXTjpGhhEviQPsXQp-EBUMTQxQzM5NE5LT0pRSkg0WDBWUDc0N0tPOS4u&embed=true"
            target="_blank"
            rel="noopener noreferrer">Contact us </a> today to schedule a consultation.
                    </p>
                    <Button
                      variant="outline-secondary"
                      onClick={() =>
                        scrollToComponent(this.Tabs, {
                          offset: 0,
                          align: "top",
                          duration: 1500
                        })
                      }
                    >
                      Learn More
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
          <Row
            id="serviceTabContainer"
            ref={section => {
              this.Tabs = section;
            }}
          >
            <Tabs
              defaultActiveKey={1}
              className="flex-column flex-sm-row"
              id="uncontrolled-tab-example"
            >
              <Tab eventKey={1} title="Design Process">
                <Row>
                  <Col sm={12} md={12} lg={12} className="tabCol">
                    <p className="tabText">
                      As a collaborative experience, our engineers work to mold
                      your ideas together; breathing life into the vision of
                      your product. 
                      <br />
                      <br />
                      With 25 years of experience in
                      upholding regulatory compliances and maintaining
                      certification with the FDA, CE, CSA, UL, and as a
                      certified ISO 13485-2016 Class II Medical Device
                      Manufacturer, our extensive experience and qualifications
                      enable us to provide you the expertise essential for the
                      success of your product. 
                      <br />
                      <br />
                      Through the comprehensive process
                      of New Product Introduction (NPI), we create the blueprint
                      to best prepare for cost effective manufacturability to
                      yield you the maximized results. 
                      <br />
                      <br />
                      <span className="hstext">H&S</span> provides services for
                      any phase of your project, including the following NPI
                      services:
                      <br />
                      <br />
                    </p>
                 
                 
                    <ListGroup >
                      <ListGroup.Item>
                        <b>Feasibility Phase</b>

                        <p>
                          This vital phase evaluates a products success
                          potential. Upon expert research and development to
                          evaluate the viability of a proposed project, a
                          project team is then assembled.
                        </p>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>Design Conceptualization</b>

                        <p>
                          The proof of concept for your idea transpires in this
                          phase. In depth research and early designs provide the
                          well-defined project scope to determine a plan for
                          product development.
                        </p>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>Research, Development & Prototyping</b>

                        <p>
                          Your product is developed into a functioning prototype
                          while required documentation and key processes are
                          established to produce a working model of your idea.
                        </p>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>Commercialization</b>

                        <p>
                          Once your product idea is realized, we work to
                          determine the methodology of manufacturing along with
                          supporting documentation, 510K filing assistance,
                          tooling, and fixtures needed in the production
                          process.
                        </p>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>Production Phase</b>

                        <p>
                          The production process and documentation associated
                          with manufacturing is realized in this phase. New
                          product is developed and reviewed by an
                          interdisciplinary team to determine the optimal path
                          to client delivery and a First Article Inspection
                          Report executed.
                        </p>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>After Market Phase </b>

                        <p>
                          Our expansive internal resources and highly regulated
                          processes combine to deliver supreme level
                          single-sourced provisions beyond the NPI itself. Our
                          business support staff seamlessly executes everything
                          from sales and distribution, customer and technical
                          support, and even documentation and regulatory record
                          maintenance supporting the continued success of your
                          launched product.
                        </p>
                      </ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Row>
              </Tab>
              {/* <Tab eventKey={2} title="Exchange Program">
                <Row>
                
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText">
                      We have what you need, in stock and ready to ship. Postage
                      labels are always provided for the return of defective
                      components. This program truly allows you to receive parts
                      both faster and at a fraction of the cost than the
                      competition.
                    </p>
                  </Col>
                  <Col sm={12} md={6} lg={6} className="tabCol">
                    <img src={Exchange} className="img-responsive tabImg" 
                    alt="Exhange Products"/>
                  </Col>
                </Row>
              </Tab> */}
              {/* <Tab eventKey={3} title="Recycle Program">
                <Row>
                  <Col sm={12} md={4} lg={4} className="tabCol">
                    <img src={Recycle} className="img-responsive tabImg" 
                    alt="Recycle Center"/>
                  </Col>
                  <Col
                    sm={12}
                    md={8}
                    lg={8}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText">
                      Our Recycle Program was developed specifically to save you
                      money. With inventory directly from dialysis machines,
                      each part has been thoroughly cleaned, tested, and
                      disinfected for resale to you. This reconditioned parts
                      program option is not only cost effective, but
                      environmentally friendly as it prevents landfill excess. 
                    </p>
                  </Col>
                </Row>
              </Tab> */}
              {/* <Tab eventKey={4} title="Water Treatment Service Center">
                <Row>
                 
                  <Col
                    sm={12}
                    md={8}
                    lg={8}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText">
                      H&S proudly services a variety of water treatment systems
                      and equipment, including the B. Braun AQUABoss and the WRO
                      300 Dialysis Water Purification System. This program is
                      designed for critical equipment services while maintaining
                      your current unique system modifications.
                    </p>
                  </Col>
                  <Col sm={12} md={4} lg={4} className="tabCol">
                    <img src={Water} className="img-responsive tabImg" 
                    alt="Water Treatment"/>
                  </Col>
                </Row>
              </Tab> */}
            </Tabs>
          </Row>
          <LandingBanner />
          <ContactForm />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Engineering;

import React, { Component, useState } from "react";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {
  Row,
  Tabs,
  Tab,
  Col,
  Card,
  Image,
  Alert,
  Accordion,
} from "react-bootstrap";
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import ContactForm from "./contact-form";
import Footer from "./footer.js";
import CharlesHeadshot from "./images/charlie-johnson-headshot.jpg";
import MarshallHeadshot from "./images/marshallHeadshot.jpg";
import EricHeadshot from "./images/DeraneyEric_headshot.JPG";

import LandingBanner from "./landing-banner";
import styled from "styled-components";
import { ChevronUp, ChevronDown } from "react-bootstrap-icons";
import DialysisMachine from "./images/DialysisMachineRow.JPG";
import QueryParamsDemo from "./scrollerComponent.js";




const StyledChevronUp = styled(ChevronUp)`
  position: absolute;
  right: 0;
  margin-right: 0.4em;
  margin-top: 0.3em;
`;
const StyledChevronDown = styled(ChevronDown)`
  position: absolute;
  right: 0;
  margin-right: 0.4em;
  margin-top: 0.4em;
`;
//custom accordion buttons with a toggling chevron
function CustomToggle({ children, eventKey, inputVariable}){
  const [isActive, setIsActive] = useState(inputVariable);
  //globalvariable is currently undefined
  const decoratedOnClick = useAccordionButton(eventKey, () =>
  [setIsActive(isActive => !isActive)],
  );

  return (
    <button
      type="button"
      class="accordionButton"
      onClick={decoratedOnClick}
    >
      {[children]}
      {isActive ? <StyledChevronUp size={20}/>: <StyledChevronDown size={20}/>}
    </button>
  );
}


const FieldText =
  "Complete on-site preventative maintenance services for your operating room equipment!";
class FieldServiceEngineering extends Component {
  render() {
    var firstAccordionArrow = true;
    var secondAccordionArrow = false;
    var thirdAccordionArrow = false;
        //set starting accordion by getting window location
    //this function largely does the same thing as QueryParamsDemo
    //but I couldnt get QueryParamsDemo to work for setting the accordion defaultActiveKey
    function setStartingAccordionDefault() {
      //check what accordion we want to be
      if (window.location.search == "?name=perfusion-equipment-maintenance" )
      {
        return("perfusion-equipment-maintenance");
      }
      else if  (window.location.search == "?name=dialysis-equipment-services" )
      {
        firstAccordionArrow = false;
        secondAccordionArrow = true;
        
        return("dialysis-equipment-services");
      }
      else if  (window.location.search == "?name=engineering-team-leads" )
      {
        firstAccordionArrow = false;
        
        thirdAccordionArrow = true;
        return("engineering-team-leads");
      }
     else //if its default
     {
      return("perfusion-equipment-maintenance");
     }    
    }

 
    return (
      <div>
        <QueryParamsDemo />   
        <div className="bodyContainer">
          <LandingBanner
            id="fieldServicelandingBannerServices"
            text={FieldText}
          />
          <Row>
            <div class="jumbotron" id="jumbotronNoImage">
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <div id="spacingDiv" className="fieldservicesheaderp">
                    <br />
                    <h1 className="productshead">
                      <span className="HSLogoText nonWhite">H&S</span> Field
                      Service Engineering
                    </h1>
                    <p id="servicesHeaderText">
                      <span className="HSLogoText nonWhite">H&S</span>{" "}
                      proudly provides an extensive
                      variety of preventative and corrective maintenance service
                      options to maintain the life of your capital equipment and
                      keep your facilities operations running efficiently. {" "}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
          <Row>
            <LandingBanner />
          </Row>
          <Row
            id="serviceTabContainer"
            ref={(section) => {
              this.Services = section;
            }}
          >
            <div className="jumbotron" id="fieldServiceEngineeringJumbo">
            {/*Accordion Start */}
            <Accordion
              defaultActiveKey={setStartingAccordionDefault()}
              className="flex-column flex-sm-row"
              id="accordion"
            >
              {/* Accordion 1 */}
              <Card title="Perfusion Equipment Maintenance" id="firstTab" >
                <Card.Header className="accordionHeader">
              <Link to="/field-service-engineering?name=perfusion-equipment-maintenance">
                <CustomToggle eventKey={"perfusion-equipment-maintenance"} inputVariable={firstAccordionArrow}>
                <b>Perfusion Equipment Maintenance</b>
                </CustomToggle>
                </Link>
                </Card.Header>

                <Accordion.Collapse eventKey={"perfusion-equipment-maintenance"}>
                  <Card.Body>
                <Row>
                  <Col
                    sm={12}
                    md={12}
                    lg={12}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText">
                      We proudly provide preventative and corrective maintenance
                      services to extend the life of critical lifesaving
                      equipment; keeping your facilities operations running
                      efficiently. On-site services are performed by our
                      certified field service engineers with the advantage of
                      competitive, flat rate pricing to help keep your facility
                      costs down.
                      <br />
                      <br />
                      Featured Complete On-Site Maintenance Services: 
                      <br />
                      <br />
                    </p>

                    <Row>
                      <Col sm={12} md={6} lg={6}>
                        <Alert className="valuesText" variant="secondary">
                          <Alert.Heading>PERFUSION EQUIPMENT</Alert.Heading>
                          <p className="alertText">
                            Stӧckert® SIII & S5
                            <br />
                            Stӧckert® Centrifugal Pump (SCP) 
                            <br />
                            Stӧckert® Centrifugal Pump Consoles (SCPC)
                            <br />
                            Medtronic 540E & 550 Bio Consoles
                            <br />
                            Quest Medical MPS® 1 & 2
                          </p>
                        </Alert>
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Alert className="valuesText" variant="secondary">
                          <Alert.Heading>HEATER/COOLERS</Alert.Heading>
                          <p className="alertText">
                            Stӧckert® 3T
                            <br />
                            Cincinnati Sub-Zero Hemotherm
                            <br />
                            Sarns&trade;
                          </p>
                        </Alert>
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Alert className="valuesText" variant="secondary">
                          <Alert.Heading>BLOOD SALVAGE SYSTEMS</Alert.Heading>
                          <p className="alertText">
                            Sorin Group XTRA
                            <br />
                            COBE® Brat 2
                          </p>
                        </Alert>
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Alert className="valuesText" variant="secondary">
                          <Alert.Heading>EXCLUSIVE SERVICES</Alert.Heading>
                          <p className="alertText">
                            Century® HLM
                            <br />
                            Century® HLM Pumps
                          </p>
                        </Alert>
                      </Col>
                       
                    </Row>

                    <br />
                    <p>
                      <i>
                        Don’t see your equipment listed? Give us as call or{" "}
                        <a target="_blank" href="https://forms.office.com/Pages/ResponsePage.aspx?id=yZ61zV7aiEKPav757gS-nSlEXTjpGhhEviQPsXQp-EBUNjI2MlpMN0pPVVZWVE5RNElHR0JXNldGNi4u">contact us</a> . Our specially
                        trained field service representatives are standing by to
                        assist you.
                      </i>
                    </p>
                  </Col>
                </Row>
                </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* Accordion 2 */}
              <Card title="Dialysis Equipment Services" id="secondTab">
              <Card.Header className="accordionHeader">
                <Link to="/field-service-engineering?name=dialysis-equipment-services">
                  <CustomToggle eventKey={"dialysis-equipment-services"} inputVariable={secondAccordionArrow}>
                  <b>Dialysis Equipment Services</b>
                  </CustomToggle>
                </Link>
              </Card.Header>
                <Accordion.Collapse eventKey={"dialysis-equipment-services"}>
                  <Card.Body>
                <Row>
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText">
                      <span className="HSLogoText nonWhite">H&S</span> performs
                      on-site installation, repairs and preventative maintenance
                      services for dialysis equipment across North America. We
                      provide one-time visit or contracted services options. Our
                      in-house training lab and expert technicians are available
                      to elevate your biomed team.
                      <br />
                      <br />
                      Our benchmark quality standards will keep your dialysis
                      equipment running smoothly and maintaining compliance for
                      years to come, all while saving your facility’s team the
                      precious time and valuable resources required to maintain
                      quality of care for your patients.
                    </p>
                  </Col>
                  <Col sm={12} md={6} lg={6} className="tabCol">
                    <img
                      src={DialysisMachine}
                      className="img-responsive tabImg"
                      alt="Dialysis Equipment"
                    />
                  </Col>
                </Row>
                </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* Accordion 3 */}
              <Card title="Engineering Team Leads" id="thirdTab">
                <Card.Header className="accordionHeader">
              <Link to="/field-service-engineering?name=engineering-team-leads">
              <CustomToggle eventKey={"engineering-team-leads"} inputVariable={thirdAccordionArrow}>
              <b>Field Service Engineering</b>
                </CustomToggle >
                </Link>
                </Card.Header>
                <Accordion.Collapse eventKey={"engineering-team-leads"} >
                  <Card.Body>
                <Row>
                  <Col
                    sm={12}
                    md={12}
                    lg={12}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText">
                    Our extensive training, diverse backgrounds and years of in-field experience allow for effective maintenance, 
                    support and longevity of older systems no longer recognized by the manufacturers.
                    </p>
                    <br />
                  </Col>
                </Row>
                <div class="jumbotron"
                  ref={(section) => {
                    this.Engineers = section;
                  }}
                  id="fieldServiceEngineerSection"
                >
                  <h2 id="FSHeader">
                    <span className="HSLogoText nonWhite">H&S</span>{" "}
                    Field Service Engineers
                  </h2>
                  <br />
                  <br />
                  <br />
                  <Row>
                    <Col sm={12} md={4}>
                      <Card border="light" style={{ backgroundColor: '#fbfbfb' }}>
                        <Card.Header style={{ textAlign: 'center' }}>
                          Charlie Johnson, Field Service Engineer
                        </Card.Header>

                        <Image
                          src={CharlesHeadshot}
                          className="FieldEngineerHeadshot"
                          
                        />

                        <Card.Body style={{ textAlign: 'justified' }}>
                          Charlie has been one of our expert field service
                          technicians since 2004, specializing in the
                          Century&trade; Heart & Lung Bypass Machines. He
                          provides on-site preventative and medical equipment
                          maintenance in operating rooms throughout the United
                          States.
                          <br />
                          <br />
                          Charlie is a Certified Hemodialysis Technician and has
                          worked as a Nephrology Clinical Technician and
                          Nephrology Biomedical Technician for over 35 years. He
                          received his electronic training through Delta
                          Ouachita Technical Institute in Louisiana. During his
                          time in dialysis, Charlie had the opportunity to work
                          on all makes and models of dialysis machines and
                          related ancillary medical equipment which made him a
                          good fit for{" "}
                          <span className="HSLogoText nonWhite">H&S</span>{" "}
                          Customer Relations and Technical Support.
                          <br />
                          <br />
                          <br />
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col sm={12} md={4}>
                      <Card border="light" style={{ backgroundColor: '#fbfbfb' }}>
                        <Card.Header style={{ textAlign: 'center' }}>
                          Marshall Anders, Field Service Engineer
                        </Card.Header>

                        <Image
                          src={MarshallHeadshot}
                          className="FieldEngineerHeadshot"
                          
                        />

                        <Card.Body style={{ textAlign: 'justified' }}>
                          Marshall is one of our field service engineers,
                          joining{" "}
                          <span className="HSLogoText nonWhite">H&S</span> in
                          June of 2009 and has increased our customer base by
                          offering Preventative Maintenance (PM) Service on a
                          wide range of Perfusion Equipment.
                          <br />
                          <br />
                          He received his initial electronics training in the
                          Navy, then earned a degree in Electronic Technology
                          from the Community College of Denver. In his nearly 27
                          years with medical equipment, Marshall transitioned
                          from Dialysis Technician to Research & Development
                          Technician. During this time, he was part of the
                          Design Team for the BRAT 2 Blood Salvage Machine.
                          Marshall has also held previous positions as a Field
                          Service Engineer, Technical Specialist and Trainer,
                          and a Service Depot Manager. This experience makes
                          Marshall the expert to repair and PM your Century™
                          products, COBE® and Stӧckert® perfusion equipment.
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col sm={12} md={4}>
                      <Card border="light" style={{ backgroundColor: '#fbfbfb' }}>
                        <Card.Header style={{ textAlign: 'center' }}>
                        Eric Deraney, Field Service Technician
                        </Card.Header>

                        <Image
                          src={EricHeadshot}
                          className="FieldEngineerHeadshot"
                          
                        />

                        <Card.Body style={{ textAlign: 'justified' }}>
                          Joining <span className="HSLogoText nonWhite">H&S</span> in 2018, Eric quickly became a vital asset to our team of Field Service Technician’s. 
                          He completes on-site installations, maintenance, and technical support for hemodialysis equipment, 
                          as well as preventative maintenance services for a wide range of perfusion equipment.
                          <br />
                          <br />
                          Having graduated from Central Georgia Tech in 1999 with a degree in Biomedical Electronics, 
                          Eric has worked in the biomedical field for over 20 years. He received his CBET certification in 2008. 
                          Eric has extensive experience with a wide variety of hemodialysis dialysis equipment, critical care ventilator equipment, anesthesia machines, 
                          electrosurgical equipment, Century™ products, COBE®, Stӧckert® perfusion equipment and Datascope balloon pumps.
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
                </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            </div>
          </Row>

          <LandingBanner text="Our field service engineers are the technical experts you need." />
          <div id="contact">
            <ContactForm addressOverride="service@hstsvc.com" />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default FieldServiceEngineering;

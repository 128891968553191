import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import BrandLogo from "./images/HS_NIPRO_LOGO.png";

export const Navigation = (props) => {
  return (
    <Navbar
      id="header"
      fixed="top"
      className="bg-light justify-content-between"
      collapseOnSelect
      expand="xl"
    >
      <Navbar.Brand href="/">
        <img
          className="img-responsive hsBrand"
          src={BrandLogo}
          alt="H&S Logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className="navigationRight">
        {/* <Nav.Link eventKey={1} href="/">
          Home
        </Nav.Link> */}
        <NavDropdown title="Our&nbsp;Services" id="basic-nav-dropdown">
          <NavDropdown.Item href="/services">
            Hemodialysis Parts Service Center
          </NavDropdown.Item>
          <NavDropdown.Item href="/meters-calibration-solutions">
            Calibrations, Solutions, & Reference Meters
          </NavDropdown.Item>
          <NavDropdown.Item href="/products">
            {" "}
            <span className="hstext">H&S </span>
            Biomedical Technician Assist Devices
          </NavDropdown.Item>
          <NavDropdown.Item href="/technical-support">
            Technical Support
          </NavDropdown.Item>
          {/* <NavDropdown.Item href="/distribution-logistics">
            Distribution & Logistic Services
          </NavDropdown.Item> */}
          <NavDropdown.Item href="/field-service-engineering">
            Field Service Engineering
          </NavDropdown.Item>
          <NavDropdown.Item href="/hsmanufacturing/manufacturing-services">
            Manufacturing Services
          </NavDropdown.Item>
        </NavDropdown>
        <Nav.Link eventKey={3} href="/downloadsFormsPage">
          Order Forms
        </Nav.Link>
        <Nav.Link eventKey={4} href="/downloadsPage">
          Products & Pricing
        </Nav.Link>
        <Nav.Link eventKey={5} href="/who-we-are">
          Who&nbsp;We&nbsp;Are
        </Nav.Link>
        <Nav.Link eventKey={6} href="/careers">
          Careers
        </Nav.Link>
        <Nav.Link eventKey={7} href="/contact">
          Contact
        </Nav.Link>
        <Nav.Link eventKey={8} onClick={() => props.showMailingListModal()}>
          Join Our Email List
        </Nav.Link>
      </Navbar.Collapse>
    </Navbar>
  );
};

import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import styled from "styled-components";

const StyledCarouselItem = styled(Carousel.Item)`
min-height: 300px !important;
background-color: white;

@media only screen and (max-width: 768px) {
  min-height: 515px !important;
`;

const StyledCarouselCaption = styled(Carousel.Caption)`
  color: black;
`;

class Testimonials extends Component {
  render() {
    return (
      <div>
        <Carousel interval="7000">
          <StyledCarouselItem>
            <StyledCarouselCaption>
              <blockquote>
                <h3>
                  <i>
                    <b>1000% my go-to for parts!</b>
                  </i>
                </h3>
                "Dialysis parts are just like new and it saves me so much money
                instead of buying new. <br /> H&S is the first place I will
                always go!"
                <br />
                <br />
                <cite>
                  Lucas Martin,
                  <br /> Biomed Technician
                </cite>{" "}
              </blockquote>
            </StyledCarouselCaption>
          </StyledCarouselItem>
          <StyledCarouselItem>
            <StyledCarouselCaption>
              <blockquote>
                <h3>
                  <i>
                    <b>Big Time Saver!</b>
                  </i>
                </h3>
                "The Genius 614 makes balance chamber testing 10 times easier!{" "}
                <br /> You no longer have to test each valve separately, can
                test the balance chamber, and if it passes you can then move
                on."
                <br />
                <br />
                <cite>Kyle Brydges, Biomed Technician</cite>{" "}
              </blockquote>
            </StyledCarouselCaption>
          </StyledCarouselItem>
          <StyledCarouselItem>
            <StyledCarouselCaption>
              <blockquote>
                <h3>
                  <i>
                    <b>Thank you!</b>
                  </i>
                </h3>
                "I appreciate all your follow-ups and follow-through regarding
                our meter needs!"
                <br />
                <br />
                <cite>
                  Christopher Atwater, Director of Technical Operations <br />{" "}
                  Bridge of Life, founded by Davita, Inc.
                </cite>{" "}
              </blockquote>
            </StyledCarouselCaption>
          </StyledCarouselItem>
          <StyledCarouselItem>
            <StyledCarouselCaption>
              <blockquote>
                <h3>
                  <i>
                    <b>I think this is going to be the next big thing.</b>
                  </i>
                </h3>
                "All my teammates love the Genius 614 and have been able to fix
                errors that stumped them for months. The directions are easy to
                follow. They have created a queue for it so they can tell where
                it is and who gets it next."
                <br />
                <br />
                <cite>Ray Ilas, Biomed Technician</cite>
              </blockquote>
            </StyledCarouselCaption>
          </StyledCarouselItem>
        </Carousel>
      </div>
    );
  }
}

export default Testimonials;

import React, { Component, useState} from "react";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { Row, Button, Col, Accordion, Card } from "react-bootstrap";
import ContactForm from "./contact-form";
import Footer from "./footer.js";
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
// import TechSupportImage2 from "./images/RevisedImages/DSC02533_Cropped_2.jpg";
// import TechSupportImage1 from "./images/newImages/TechSupportGroup.jpg";
// import TechSupportImage3 from "./images/RevisedImages/IMG_2527.jpg";
import TechSupport1 from "./images/DSC08646.jpg";
import Claudia from "./images/Claudia.jpg";
import LandingBanner from "./landing-banner";
import styled from "styled-components";
import { ChevronUp, ChevronDown } from "react-bootstrap-icons";
import QueryParamsDemo from "./scrollerComponent.js"


const StyledChevronUp = styled(ChevronUp)`
  position: absolute;
  right: 0;
  margin-right: 0.4em;
  margin-top: 0.3em;
`;
const StyledChevronDown = styled(ChevronDown)`
  position: absolute;
  right: 0;
  margin-right: 0.4em;
  margin-top: 0.4em;
`;

//custom accordion buttons with a toggling chevron
function CustomToggle({ children, eventKey, globalVariable}){
  const [isActive, setIsActive] = useState(globalVariable);
  //globalvariable is currently undefined
  const decoratedOnClick = useAccordionButton(eventKey, () =>
  [setIsActive(isActive => !isActive)],
  );

  return (
    <button
      type="button"
      class="accordionButton"
      onClick={decoratedOnClick}
    >
      {[children]}
      {isActive ? <StyledChevronUp size={20}/>: <StyledChevronDown size={20}/>}
    </button>
  );
}


const techBanner = `Our expert technical support team is standing by for all your troubleshooting needs, all while providing you with the ideal personalized customer experience.`;
class TechSupport extends Component {

  render() {
    var firstAccordionArrow = true;
    var secondAccordionArrow = false;
   function setStartingAccordionDefault() {
      //check what accordion we want to be
      if (window.location.search == "?name=customer-service" )
      {
        firstAccordionArrow = false;
        secondAccordionArrow = true;
        return("customer-service");
  
      }
      else if  (window.location.search == "?technical-support" )
      {
        return("technical-support");
      }
     else //if its default
     {
      return("technical-support");
     }    
    }
    console.log(this.test);
    return (
      <div>
        <QueryParamsDemo />    
        <div className="bodyContainer" id="techSupportHeaderRow">
          <LandingBanner id="landingBannerServices" text={techBanner} />
          <div className="jumbotron" id="techSupportTabsDiv">
          {/*Accordion start */}
            <Accordion
              defaultActiveKey={setStartingAccordionDefault()}
            >
              {/*Accordion tab # 1 */}
              <Card title="Technical Support" id="firstTab">
                <Card.Header className="accordionHeader">
                <Link to="/technical-support?name=technical-support">
                  <CustomToggle eventKey="technical-support" globalVariable={firstAccordionArrow}>
                  <b>Technical Support</b>
                  </CustomToggle>
                  </Link>
                </Card.Header>
                   
                  <Accordion.Collapse eventKey={"technical-support"}>
                    <Card.Body>
                <Row>
                  <Col sm={12} md={6} lg={6} className="tabCol">
                    <p className="techsupportText">
                      <span className="hstext">H&S</span> provides an elite
                      level of complimentary technical support, unlike anything
                      you will experience anywhere else! Our highly skilled team
                      of specialists boast over 25 years of extensive knowledge
                      and expertise to accurately diagnose your dialysis machine
                      related errors from alarms to downed machines.
                      <br />
                      <br />
                      <Button variant="outline-secondary" target="_blank" href="https://forms.office.com/Pages/ResponsePage.aspx?id=yZ61zV7aiEKPav757gS-nSlEXTjpGhhEviQPsXQp-EBUMktFQURaUU9SS0ozV0I0QlNJMVlMUExaVy4u">
                        Contact Us for Support
                      </Button>
                    </p>
                  </Col>
                  <Col sm={12} md={6} lg={6} className="tabCol">
                    <img
                      src={TechSupport1}
                      alt="techSupport worker"
                      className="tabImg"
                    />
                  </Col>
                </Row>
              </Card.Body>
              </Accordion.Collapse>
              </Card>
              {/*Accordion tab # 2 */}
              <Card title="Customer Service" id="secondTab">
                <Card.Header className="accordionHeader">
                <Link  to="/technical-support?name=customer-service">
                  <CustomToggle eventKey="customer-service" globalVariable={secondAccordionArrow}> 
                  <b>Customer Service</b>
                  </CustomToggle>
                  
                  </Link>
                </Card.Header>
                <Accordion.Collapse eventKey="customer-service">
                  <Card.Body>
                <Row>
                  <Col sm={12} md={6} lg={6} className="tabCol">
                    <p className="techsupportText">
                      The focus of this team is simple – if we can help you fix
                      it over the phone, we will! You can count on our dedicated
                      team to be there for you without the hassle of automated
                      phone services. This exclusive service plays a fundamental
                      role in what is the supreme experience that is{" "}
                      <span className="hstext">H&S</span>: to solve your problem
                      as quickly as possible so that you can realign your focus
                      where it needs to be – on patient care.
                      <br />
                      <br />
                      <Button variant="outline-secondary" target="_blank" href="https://forms.office.com/Pages/ResponsePage.aspx?id=yZ61zV7aiEKPav757gS-nSlEXTjpGhhEviQPsXQp-EBUMzRLOEhHNUcwWTlNNkU5STFCOTVINTVRWS4u">
                        Contact Us for Support
                      </Button>
                    </p>
                  </Col>
                  <Col sm={12} md={6} lg={6} className="tabCol">
                    <img
                      src={Claudia}
                      alt="customer support"
                      className="tabImg"
                    />
                  </Col>
                </Row>
                </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>

          {/* <Row>
            <TextScroller text="Blood Pressure Module Kits & Exchange Available"/>
          </Row> */}

          <ContactForm addressOverride="techsupport@hstsvc.com" />
        </div>
        <Footer />
      </div>
    );
  }
}

export default TechSupport;

import { Row, Col, ListGroup, Alert } from "react-bootstrap";

import React, { Component } from "react";
import styled from "styled-components";

const Styledalert = styled(Alert)`
  margin: 20px;
`;

class Resources extends Component {
  render() {
    return (
      <Styledalert variant="secondary">
        <Alert.Heading>Resources</Alert.Heading>
        <Row>
          <Col sm={12} md={4}>
            <ListGroup>
              <ListGroup.Item>
                <a target="_blank" rel="noreferrer" href="https://nipro.com/">
                  Nipro Medical Corporation
                </a>
              </ListGroup.Item>

              <ListGroup.Item>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://centuryhlm.com/"
                >
                  Century, HLM
                </a>
              </ListGroup.Item>

              <ListGroup.Item>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.cardioquip.com/"
                >
                  CardioQuip
                </a>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col sm={12} md={4}>
            <ListGroup>
              <ListGroup.Item>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://dialysistechnical.freeforums.net/"
                >
                  Dialysis Discussion Forum
                </a>
              </ListGroup.Item>

              <ListGroup.Item>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://nationalkidneyfoundationresourceguide.com/"
                >
                  National Kidney Foundation Resource Guide
                </a>
              </ListGroup.Item>

              <ListGroup.Item>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.davita.com"
                >
                  DaVita Kidney Care
                </a>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col sm={12} md={4}>
            <ListGroup>
              <ListGroup.Item>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.dialysistech.net"
                >
                  NANT
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.bridgeoflifeinternational.org"
                >
                  Bridge of Life
                </a>
              </ListGroup.Item>

              <ListGroup.Item>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.campnotawheeze.org"
                >
                  Camp-Not-A-Wheeze
                </a>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Styledalert>
    );
  }
}

export default Resources;

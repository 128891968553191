import React, { Component, useState } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import ReactPlayer from "react-player";
import ContactForm from "./contact-form";
import Footer from "./footer.js";
import BTA from "./images/BTA-612.jpg";
import Valve from "./images/VHAT_Single_Dot_Dual_Dot_Depiction_072623.jpg";
import LandingBanner from "./landing-banner";
import {
  Row,
  Tabs,
  Tab,
  Col,
  Button,
  ListGroup,
  Card,
  Accordion,
} from "react-bootstrap";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import ProductLandingImage from "./images/HS_Exclusive_Products.jpg";
import MotorShield1 from "./images/InstalledShieldsCloseUp_SharpieRemoved.jpg";
import SquareValve from "./images/Square_Valve_Repair_Kits_211124.jpg";
import RoundValve from "./images/Round_Valve_Repair_Kits_211124.jpg";
import Genius from "./images/H&S_Genius.jpg";
import cardImage1 from "./images/BalanceChamberLeakTestPart 1_Paul_220106.png";
import cardImage2 from "./images/SingleValveTest_Paul_220106.png";
import cardImage3 from "./images/AirSeparatorTestScreen.png";
import cardImage4 from "./images/GeniusBenchtopStandSideView_Cropped.tif";
import cardImage5 from "./images/Genius_Mounted_Cropped.JPG";
import scrollToComponent from "react-scroll-to-component";
import MotorShieldPDF from "./HSMotorShield/H&SMotorShieldInstallationInstructions.pdf";
import ProductCatalog from "./msds/catalogs/HSNipro_Exclusive_Products_Catalog_20230110.pdf";
import HSGeniusInformationPDF from "./msds/HS_Nipro_Genius_614_Marketing_Flyer_040424_RevB.pdf";
import ValveAdjustmentPDF from "./msds/H&SValveRebuildInstructions.pdf";
import PartsOrderForm from "./msds/HSNipro_Parts_Order_Form_Fillable_RevA.pdf";
import styled from "styled-components";
import { ChevronUp, ChevronDown } from "react-bootstrap-icons";
import QueryParamsDemo from "./scrollerComponent.js";

const StyledChevronUp = styled(ChevronUp)`
  position: absolute;
  right: 0;
  margin-right: 0.4em;
  margin-top: 0.3em;
`;
const StyledChevronDown = styled(ChevronDown)`
  position: absolute;
  right: 0;
  margin-right: 0.4em;
  margin-top: 0.4em;
`;
//custom accordion buttons with a toggling chevron
function CustomToggle({ children, eventKey, inputVariable }) {
  const [isActive, setIsActive] = useState(inputVariable);
  //globalvariable is currently undefined
  const decoratedOnClick = useAccordionButton(eventKey, () => [
    setIsActive((isActive) => !isActive),
  ]);

  return (
    <button type="button" class="accordionButton" onClick={decoratedOnClick}>
      {[children]}
      {isActive ? (
        <StyledChevronUp size={20} />
      ) : (
        <StyledChevronDown size={20} />
      )}
    </button>
  );
}

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
`;
const StyledPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
`;

const VideoDemoCard = styled(Card)`
  border: none !important;
  background-color: #e6f0fe;
  border-radius: 15px;
  font-size: 20px;
  padding: none;
  display: flex;

  justify-content: center;
`;
const VideoDemoCardButton = styled.button`
  background-color: #0867f5;
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  font-size: 16px;
  margin: 15px;
  opacity: 1;
  transition: 0.3s;
  border-radius: 15px;
  top: 50%;
  left: 50%;

  &:hover {
    background-color: #6ba4f9;
  }
`;
const CaptionText = styled(Card.Text)`
  font-size: 14px;
  color: grey;
`;

class HSMProducts extends Component {
  render() {
    var firstAccordionArrow = true;
    var secondAccordionArrow = false;
    var thirdAccordionArrow = false;
    var fourthAccordionArrow = false;
    var fifthAccordionArrow = false;
    //set starting accordion by getting window location
    //this function largely does the same thing as QueryParamsDemo
    //but I couldnt get QueryParamsDemo to work for setting the accordion defaultActiveKey
    function setStartingAccordionDefault() {
      //check what accordion we want to be

      if (window.location.search === "?name=genius&pdf=H&S%2Genius%614%2tab") {
        return "genius";
      } else if (window.location.search == "?name=bta-612") {
        firstAccordionArrow = false;
        secondAccordionArrow = true;

        return "bta-612";
      } else if (window.location.search == "?name=motor-shield") {
        firstAccordionArrow = false;
        thirdAccordionArrow = true;
        return "motor-shield";
      } else if (window.location.search == "?name=valve-height-adjustment") {
        firstAccordionArrow = false;
        fourthAccordionArrow = true;
        return "valve-height-adjustment";
      } else if (window.location.search == "?name=valve-repair-kits") {
        firstAccordionArrow = false;
        fifthAccordionArrow = true;
        return "valve-repair-kits";
      } //if its default
      else {
        return "genius";
      }
    }
    return (
      <div>
        <QueryParamsDemo />
        <div className="bodyContainer">
          <Row>
            <div className="jumbotron">
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <img
                    src={ProductLandingImage}
                    className="img-responsive"
                    id="servicesImg"
                    alt="H&S Products"
                  />
                </Col>
                <Col sm={12} md={6} lg={6} id="servicesheaderp">
                  <div id="spacingDiv">
                    <br />
                    <h1 className="productshead">
                      <span className="HSLogoText nonWhite">H&S</span> Exclusive
                      Products
                    </h1>

                    <p id="servicesHeaderText">
                      We are always developing innovative tools and methods to
                      support the dialysis industry, continually evolving to
                      both predict and exceed customer demand.{" "}
                    </p>
                    <Row>
                      <Col sm={12} lg={6}>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <a
                              href={
                                ProductCatalog +
                                "?utm_source=hst_products_page&utm_medium=display&utm_campaign=product_catalog_20230110"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="hstext">H&S</span> Nipro Exclusive Product Catalog_20230110
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <a
                              href={
                                PartsOrderForm +
                                "?utm_source=hst_products_page&utm_medium=display&utm_campaign=parts_order_form_220816"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="hstext">H&S</span> Nipro Parts Order Form
                            </a>
                          </ListGroup.Item>
                        </ListGroup>
                        <br />
                        <br />
                        <Button
                          variant="outline-secondary"
                          onClick={() =>
                            scrollToComponent(this.Products, {
                              offset: 0,
                              align: "top",
                              duration: 1500,
                            })
                          }
                        >
                          Learn More
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
          <Row>
            <LandingBanner />
          </Row>
          <Row
            className="productsTabContainer"
            id="products"
            ref={(section) => {
              this.Products = section;
            }}
          >
            <div className="jumbotron" id="fieldServiceEngineeringJumbo">
              {/*Accordion Start*/}
              <Accordion
                defaultActiveKey={setStartingAccordionDefault()}
                id="accordion"
              >
                {/*Accordion 1*/}
                <Card title="H&S Genius 614" id="firstTab">
                  <Card.Header className="accordionHeader">
                    <Link
                      className="link-title-accordion"
                      to="/products?name=genius&pdf=H&S%2Genius614%2tab" // testing to see if this works
                    >
                      <CustomToggle
                        eventKey={"genius"}
                        inputVariable={firstAccordionArrow}
                      >
                        <b>H&S Genius 614</b>
                      </CustomToggle>
                    </Link>
                  </Card.Header>

                  <Accordion.Collapse eventKey={"genius"}>
                    <Card.Body>
                      <Row>
                        <Col
                          sm={12}
                          md={6}
                          lg={6}
                          id="servicesheaderp"
                          className="tabCol"
                        >
                          <p className="tabText">
                            The H&S Genius 614 is a touch screen device designed
                            to effortlessly test and verify the hydraulic
                            balancing chamber, solenoid valves, and air
                            separator within the hydraulic path in Fresenius
                            hemodialysis machines.
                            <br />
                            <br />
                            With simple on-screen steps, the testing process
                            validates all electrical and hydraulic components
                            are functioning correctly.
                            <br />
                            <br />
                            {/*Put the document download links here */}
                            <a
                              href={HSGeniusInformationPDF}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="hstext">H&S</span> Genius 614
                              Information
                            </a>
                            <br />
                            <br />
                            <div>Sign up to test out the Genius 614 in the field!</div>
                            <div style={{ fontStyle: "italic", fontSize: "12px" }}>*simply pay for the cost of shipping</div>
                            
                            <VideoDemoCardButton>
                            <a
                                    href="https://forms.office.com/r/zxzRGQsxs9"
                                    rel="noreferrer"
                                    style={{ color: "white" }}
                                    target="_blank"
                                  >
                                    {" "}
                                    Evaluation Sign Up
                                  </a>
                                  </VideoDemoCardButton>
          <br /><br />
                          </p>
                        </Col>
                        <Col sm={12} md={6} lg={6} className="tabCol">
                          <img
                            src={Genius}
                            className="img-responsive tabImg"
                            alt="H&S Genius 614"
                          />
                        </Col>
                      </Row>
                      <Col sm={12} md={6} lg={6}>
                        <VideoDemoCard>
                          <Card.Body>
                            <Row>
                              <Col
                                lg={{ order: 1 }}
                                md={{ order: 1 }}
                                sm={{ order: 2 }}
                              >
                                <Card.Text>
                                  <ul>
                                    <li>
                                      Complete balance chamber functionality
                                      testing in under 15 minutes
                                    </li>
                                  </ul>
                                </Card.Text>
                                <VideoDemoCardButton>
                                  <a
                                    href="https://youtu.be/4UmtzzOZBiA"
                                    rel="noreferrer"
                                    style={{ color: "white" }}
                                    target="_blank"
                                  >
                                    {" "}
                                    Watch Demo
                                  </a>
                                </VideoDemoCardButton>
                              </Col>
                              <Col
                                lg={{ order: 2 }}
                                md={{ order: 2 }}
                                sm={{ order: 1 }}
                              >
                                <Card.Img variant="top" src={cardImage1} />
                              </Col>
                            </Row>
                          </Card.Body>
                        </VideoDemoCard>

                        <br />

                        <VideoDemoCard>
                          <Card.Body>
                            <Row>
                              <Col
                                lg={{ order: 1 }}
                                md={{ order: 1 }}
                                sm={{ order: 2 }}
                              >
                                <Card.Text>
                                  <ul>
                                    <li>
                                      Individual solenoid valve testing in under
                                      60 seconds
                                    </li>
                                  </ul>
                                </Card.Text>
                                <VideoDemoCardButton>
                                  <a
                                    href="https://youtu.be/8J_BIYFQeMI?si=zniGlKkemSEuPa1p"
                                    style={{ color: "white" }}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {" "}
                                    Watch Demo
                                  </a>
                                </VideoDemoCardButton>
                              </Col>
                              <Col
                                lg={{ order: 2 }}
                                md={{ order: 2 }}
                                sm={{ order: 1 }}
                              >
                                <Card.Img variant="top" src={cardImage2} />
                              </Col>
                            </Row>
                          </Card.Body>
                        </VideoDemoCard>

                        <br />

                        <VideoDemoCard>
                          <Card.Body>
                            <Row>
                              <Col
                                lg={{ order: 1 }}
                                md={{ order: 1 }}
                                sm={{ order: 2 }}
                              >
                                <Card.Text>
                                  <ul>
                                    <li>Air separator testing in 2 minutes</li>
                                  </ul>
                                </Card.Text>
                                <VideoDemoCardButton>
                                  <a
                                    href="https://youtu.be/PsutX9Z5qcs?si=EvL3KBvKX4jlR_eO"
                                    style={{ color: "white" }}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {" "}
                                    Watch Demo
                                  </a>
                                </VideoDemoCardButton>
                              </Col>
                              <Col
                                lg={{ order: 2 }}
                                md={{ order: 2 }}
                                sm={{ order: 1 }}
                              >
                                <Card.Img variant="top" src={cardImage3} />
                              </Col>
                            </Row>
                          </Card.Body>
                        </VideoDemoCard>

                        <br />

                        <VideoDemoCard>
                          <Card.Body>
                            <Row>
                              <Col
                                lg={{ order: 1 }}
                                md={{ order: 1 }}
                                sm={{ order: 2 }}
                              >
                                <Card.Text>
                                  <ul>
                                    <li>
                                      Comes with stand for ease of use while
                                      performing benchtop testing
                                    </li>
                                  </ul>
                                </Card.Text>
                              </Col>
                              <Col
                                lg={{ order: 2 }}
                                md={{ order: 2 }}
                                sm={{ order: 1 }}
                              >
                                <Card.Img variant="top" src={cardImage4} />
                                <CaptionText>
                                  Benchtop Stand Side View
                                </CaptionText>
                              </Col>
                            </Row>
                          </Card.Body>
                        </VideoDemoCard>

                        <br />

                        <VideoDemoCard>
                          <Card.Body>
                            <Row>
                              <Col
                                lg={{ order: 1 }}
                                md={{ order: 1 }}
                                sm={{ order: 2 }}
                              >
                                <Card.Text>
                                  <ul>
                                    <li>
                                      Optional mounting bracket available for
                                      in-machine verification (P/N: 39008)
                                    </li>
                                  </ul>
                                </Card.Text>
                              </Col>
                              <Col
                                lg={{ order: 2 }}
                                md={{ order: 2 }}
                                sm={{ order: 1 }}
                              >
                                <Card.Img variant="top" src={cardImage5} />
                                <CaptionText>
                                  Mounting Bracket In-Machine View
                                </CaptionText>
                              </Col>
                            </Row>
                          </Card.Body>
                        </VideoDemoCard>
                      </Col>

                      <Row></Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                {/*Accordion 2*/}
                <Card
                  className="productTabs"
                  title="H&S BTA-612"
                  id="secondTab"
                >
                  <Card.Header className="accordionHeader">
                    <Link
                      className="link-title-accordion"
                      to="/products?name=bta-612"
                    >
                      <CustomToggle
                        eventKey={"bta-612"}
                        inputVariable={secondAccordionArrow}
                      >
                        <b>H&S BTA-612</b>
                      </CustomToggle>
                    </Link>
                  </Card.Header>

                  <Accordion.Collapse eventKey={"bta-612"}>
                    <Card.Body>
                      <Row>
                        <Col
                          sm={12}
                          md={6}
                          lg={6}
                          id="servicesheaderp"
                          className="tabCol"
                        >
                          <p className="tabText">
                            The H&S BTA-612 Selectable Voltage Power Supply
                            provides effortless out-of-machine valve, pump and
                            motor verification.
                            <br />
                            <br />
                            By bringing the power directly to the work bench,
                            this handheld, portable and lightweight device was
                            developed to save vital testing time.
                          </p>
                        </Col>
                        <Col sm={12} md={6} lg={6} className="tabCol">
                          <img
                            src={BTA}
                            className="img-responsive tabImg"
                            alt="H&S BTA Product"
                          />
                        </Col>
                        <Col
                          lg={12}
                          sm={12}
                          className="styled-player centerText"
                        >
                          <h3>
                            See how the H&S BTA-612 saves time rebuilding
                            solenoid valves!
                          </h3>
                        </Col>
                        <Col lg={6} sm={12} className="styled-player">
                          <PlayerWrapper>
                            <StyledPlayer
                              url="https://www.youtube.com/watch?v=NO3brcfDVT0"
                              width="100%"
                              height="100%"
                            />
                          </PlayerWrapper>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                {/*Accordion 3*/}
                <Card
                  className="productTabs"
                  title="H&S Motor Shield"
                  id="thirdTab"
                >
                  <Card.Header className="accordionHeader">
                    <Link
                      className="link-title-accordion"
                      to="/products?name=motor-shield"
                    >
                      <CustomToggle
                        eventKey={"motor-shield"}
                        inputVariable={thirdAccordionArrow}
                      >
                        <b>H&S Motor Shield</b>
                      </CustomToggle>
                    </Link>
                  </Card.Header>
                  <Accordion.Collapse eventKey={"motor-shield"}>
                    <Card.Body>
                      <Row>
                        <Col sm={12} md={6} lg={6} className="tabCol">
                          <img
                            src={MotorShield1}
                            className="img-responsive tabImg"
                            alt="H&S Motor Shield Front"
                          />
                        </Col>

                        <Col
                          sm={12}
                          md={6}
                          lg={6}
                          id="servicesheaderp"
                          className="tabCol"
                        >
                          <p className="tabText">
                            The <span className="hstext">H&S</span> Motor Shield
                            is built to protect deaeration & flow pump motors
                            from damage caused by dialysate leaks. This easy to
                            install, cost-saving device is the answer to your
                            motor corrosion issues! <br />
                            <hr />
                            <a
                              href={MotorShieldPDF}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="hstext">H&S</span> Motor Shield
                              Information
                            </a>
                            <br />
                            <br />
                            <a href="https://www.youtube.com/watch?v=F7HUZRZarIg">
                              H&S Motor Shield Installation Instruction Video
                            </a>
                          </p>
                        </Col>
                        <Col
                          lg={12}
                          sm={12}
                          className="styled-player centerText"
                        >
                          <h3>
                            H&S Motor Shield Installation Instruction Video
                          </h3>
                        </Col>
                        <Col lg={6} sm={12} className="styled-player">
                          <PlayerWrapper>
                            <StyledPlayer
                              url="https://www.youtube.com/watch?v=F7HUZRZarIg"
                              width="100%"
                              height="100%"
                            />
                          </PlayerWrapper>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                {/*Accordion 4*/}
                <Card
                  className="productTabs"
                  title="H&S Valve Height Adjustment Tool"
                  id="fourthTab"
                >
                  <Card.Header className="accordionHeader">
                    <Link
                      className="link-title-accordion"
                      to="/products?name=valve-height-adjustment"
                    >
                      <CustomToggle
                        eventKey={"valve-height-adjustment"}
                        inputVariable={fourthAccordionArrow}
                      >
                        <b>H&S Valve Height Adjustment Tool</b>
                      </CustomToggle>
                    </Link>
                  </Card.Header>

                  <Accordion.Collapse eventKey={"valve-height-adjustment"}>
                    <Card.Body>
                      <Row>
                        <Col sm={12} md={6} lg={6} className="tabCol">
                          <img
                            style={{ marginLeft: "7%",  }}
                            src={Valve}
                            className="img-responsive tabImg"
                            alt="H&S Height Valve"
                          />
                        </Col>
                        <Col
                          sm={12}
                          md={6}
                          lg={6}
                          id="servicesheaderp"
                          className="tabCol"
                        >
                          <p className="tabText">
                            The H&S Valve Height Adjustment Tool is designed for
                            the rebuild of both EKS/Square and Ratio/Round style Fresenius
                            solenoid valves. This virtually indestructible tool
                            ensures that the plunger assembly is properly
                            adjusted by verifying the critical height
                            requirement and significantly reducing
                            troubleshooting and adjustment time.
                            <br />
                            <br />
                            <a href={ValveAdjustmentPDF}>
                              H&S Valve Rebuild Instructions
                            </a>
                          </p>
                        </Col>
                        <Col
                          lg={12}
                          sm={12}
                          className="styled-player centerText"
                        >
                          <h3>
                            Rebuilding Fresenius solenoid valves has never been
                            easier!
                          </h3>
                        </Col>
                        <Col lg={6} sm={12} className="styled-player">
                          <PlayerWrapper>
                            <StyledPlayer
                              url="https://www.youtube.com/watch?v=rKZqLhWQEMk&t=18s"
                              width="100%"
                              height="100%"
                            />
                          </PlayerWrapper>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                {/*Accordion 5*/}
                <Card
                  className="productTabs"
                  title="H&S Valve Repair Kits"
                  id="fifthTab"
                >
                  <Card.Header className="accordionHeader">
                    <Link
                      className="link-title-accordion"
                      to="/products?name=valve-repair-kits"
                    >
                      <CustomToggle
                        eventKey={"valve-repair-kits"}
                        inputVariable={fifthAccordionArrow}
                      >
                        <b>H&S Valve Repair Kits</b>
                      </CustomToggle>
                    </Link>
                  </Card.Header>

                  <Accordion.Collapse eventKey={"valve-repair-kits"}>
                    <Card.Body>
                      <Row>
                        <Col sm={12} md={6} lg={6} className="tabCol">
                          <img
                            style={{ marginLeft: "20%", width: "50%" }}
                            src={SquareValve}
                            className="img-responsive tabImg"
                            alt="EKS/Square Style Valve Repair Kit
                      "
                          />
                          <br />
                          <br />
                          <h3 style={{ marginLeft: "20%" }}>
                            EKS/Square Style Valve Repair Kit
                          </h3>

                          <br />

                          <img
                            style={{ marginLeft: "20%", width: "50%" }}
                            src={RoundValve}
                            className="img-responsive tabImg"
                            alt="Ratio/Round Style Valve Repair Kit"
                          />
                          <br />
                          <br />
                          <h3 style={{ marginLeft: "20%" }}>
                            Ratio/Round Style Valve Repair Kit
                          </h3>

                          <br />
                        </Col>
                        <Col
                          sm={12}
                          md={6}
                          lg={6}
                          id="servicesheaderp"
                          className="tabCol"
                        >
                          <p className="tabText">
                            Quickly and efficiently rebuild
                            EKS/Square and Ratio/Round style Fresenius solenoid
                            valves with H&S’s convenient kits. All components
                            are sourced to bring you the highest grade available
                            for ultimate corrosion and wear resistance.
                            <br />
                            <br />
                            Each multi-pack (10 each) includes:
                            <ul>
                              <li>1 Large O-Ring</li>
                              <li>1 Small O-Ring</li>
                              <li>1 Plunger Rubber Seal</li>
                              <li>1 Small Spring </li>
                            </ul>
                            <a href={ValveAdjustmentPDF}>
                              H&S Valve Rebuild Instructions
                            </a>
                          </p>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          lg={12}
                          sm={12}
                          className="styled-player centerText"
                        >
                          <h3>
                            Rebuilding Fresenius solenoid valves has never been
                            easier!
                          </h3>
                        </Col>
                        <Col lg={6} sm={12} className="styled-player">
                          <PlayerWrapper>
                            <StyledPlayer
                              url="https://www.youtube.com/watch?v=rKZqLhWQEMk&t=18s"
                              width="100%"
                              height="100%"
                            />
                          </PlayerWrapper>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </Row>
          <Row>
            <LandingBanner />
          </Row>
          <ContactForm />
        </div>
        <Footer />
      </div>
    );
  }
}

export default HSMProducts;

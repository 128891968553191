import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col, Alert, Button } from "react-bootstrap";
import LandingBanner from "./landing-banner";
import LandingCards from "./landing-cards";
import LandingCardsD from "./landing-cards-mobile";
import ContactForm from "./contact-form";
import Footer from "./footer.js";
// import Circuit from "./images/stockIMG.jpg";
import TextScroller from "./textScroller";
import Resources from "./resources";
import Testimonials from "./testimonials";

const middleBanner = (
  <p>
    {" "}
    Proudly&nbsp;Serving&nbsp;the&nbsp;Dialysis&nbsp;Community
    for&nbsp;25&nbsp;Years.{" "}
  </p>
);

// Free Technical Support! Email us at{" "}
// <a className="white" href="mailto:techsupport@hstsvc.com">
//   techsupport@hstsvc.com
// </a>

const bottomBanner = (
  <p>
    We’ve got you covered with parts services for 2008K, 2008K2, 2008T & 2008T
    BlueStar!
  </p>
);

const StyledCookieAlert = styled(Alert)`
  position: fixed;
  bottom: 10px;
  width: 50%;
  left 25%;
  z-index: 700;
  @media(max-width: 500px){
    width: 100%;
    left: 0;
  }
`;
const AlertDismissible = (props) => {
  const alertOverride = localStorage.getItem("hideAlert");

  return (
    <>
      <StyledCookieAlert
        show={alertOverride ? !alertOverride : props.showAlert}
        variant="dark"
      >
        <Alert.Heading>Cookies Usage</Alert.Heading>
        <p>
          This site uses cookies to provide you with a great user experience. By
          using this website, you accept our{" "}
          <a href="/privacy-policy"> use of cookies</a>
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() => props.hideAlert()} variant="outline-dark">
            I accept.
          </Button>
        </div>
      </StyledCookieAlert>
    </>
  );
};
class HSMLanding extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      width: window.innerWidth,
      showCovidModal: false,
      showAlert: true,
    };
  }

  hideAlert = () => {
    this.setState({ showAlert: !this.state.showAlert });
    localStorage.setItem("hideAlert", true);
  };

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  renderLandingCards = () => {
    const width = this.state.width;
    const isMobile = width <= 800;
    return isMobile ? <LandingCards /> : <LandingCardsD />;
  };

  // <CarouselComp  />

  render() {
    return (
      <div>
        <div className="bodyContainer">
          <Row>
            <LandingBanner id="landingBannerFirst" text={middleBanner} />
          </Row>

          <Row id="LandingCardsRow">{this.renderLandingCards()}</Row>
          <Row>
            <TextScroller text={bottomBanner} />
          </Row>
          <Row id="testimonials">
            <Col xs={12} lg={12}>
              <Testimonials />
            </Col>
          </Row>

          <ContactForm />
          <Resources />
        </div>
        {
          <AlertDismissible
            showAlert={this.state.showAlert}
            hideAlert={this.hideAlert}
          />
        }

        <Footer />
      </div>
    );
  }
}

export default HSMLanding;

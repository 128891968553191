import React from "react";
import { Row } from "react-bootstrap";
export const OurHistory = (props) => {
  return (
    <Row className="historyContainer" id="history">
      <p className="historyText">
      Founded in 1998, H&S Technical Services, Inc. provides exceptional products 
      and services in support of the dialysis and medical industries. Our extensive 
      repair service programs deliver continuous parts service and expert support for 
      all major brands of hemodialysis machines and a variety of cardiovascular perfusion equipment.
        <br />
        <br />
        In 2008, our operations further expanded into the medical manufacturing community. 
        Serving as a HUB for contracted  and turn-key manufacturing services, 
        equipment maintenance and repair services, our mission is to be the single source you turn to 
        for all your medical equipment needs.
        <br />
        <br />
        In 2020, H&S Technical Services, Inc. joined the Nipro Family as a subsidiary of
        Nipro Medical Corporation. This union complements our mission while elevating our 
        service scope to the global dialysis and medical industries with new and expanded endeavors.
        <br />
        <br />
        While H&S has grown and evolved through the years, the founding principles remain the same 
        and our customers can always trust that they will speak with a dedicated customer support representative 
        when they call – never an automated service.
        <br />
        <br />
        With 25 years in business and an unprecedented worldwide customer base, we are committed to quality,
        to the details and to our customers. We are continually striving to exceed expectations through 
        product line development and seamless delivery of goods. The synergetic result has elevated H&S 
        to the trusted provider for the global dialysis community today.
      </p>
    </Row>
  );
};

import React, { Component } from "react";
import ReactPlayer from "react-player";
import ContactForm from "./contact-form";
import Footer from "./footer.js";
import LandingBanner from "./landing-banner";
import {
  Row,
  Col,
  Tabs,
  Tab,
  Modal,
  Button,
  Alert,
} from "react-bootstrap";
// import HSTeam from "./images/RevisedImages/2018GroupImage.jpg";
import { OurHistory } from "./history";
import { CoreValues } from "./coreValues";
// import { BiosCarousel } from "./biosCarousel";
import styled from "styled-components";

// .react-player > div {
//   position: absolute; // Scaling will occur since parent is relative now
// }

//const PlayerWrapper = styled.div`
//  position: relative;
//  padding-top: 56.25%;
//`;

const StyledPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledModal = styled(Modal)`
  @media screen and (max-width: 475px) {
    max-width: 90%;
  }
  margin: 0 auto;
`;

const StyledAlert = styled.div`
  width: 50%;
  margin: 0 auto;
  padding-top: 40px;
  text-align: center;
`;

class WhoWeAre extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showCovidModal: false,
    };
  }
  renderCovidModal = () => {
    this.setState({ showCovidModal: !this.state.showCovidModal });
  };

  render() {
    return (
      <div>
        <div className="bodyContainer">
          <Row>
            <div class="jumbotron">
              <Row>
                <Col sm={12} md={6} lg={6} className="styled-player">
                  {/* <img
                    src={HSTeam}
                    className="img-responsive"
                    id="companyImg"
                    alt="H&S Company Photo"
              
              /> 
//the following is for a video
                  <PlayerWrapper>
                    <StyledPlayer
                      className="react-player"
                      url="https://www.youtube.com/watch?v=OVwvSQij-EE&t=4s"
                      width="100%"
                      height="100%"
                    />
                  </PlayerWrapper>
                  */}
                </Col>
                <Col sm={12} md={12} lg={12} id="servicesheaderp">
                  {/*
                  <Row>
                    <StyledAlert>
                      <Alert variant="primary">
                        We are here to support you during the COVID-19 crisis
                        <br />
                        <br />
                        <Button
                          variant="primary"
                          onClick={() => this.renderCovidModal()}
                        >
                          Learn More
                        </Button>
                      </Alert>
                    </StyledAlert>
                  </Row>
            */}
                  <div id="spacingDiv">
                    <br />
                    <h3 id="mission">Our Mission</h3>
                    <br />
                    <p id="aboutHeaderText">
                      <span className="hstext">H&S</span> Provides worldwide
                      personalized customer support, superior technical advice,
                      and quality products and services to the biomedical
                      industry. Leading the way with innovative solutions for
                      medical devices and services, we recognize that what we do
                      affects lives.
                      <br />
                      <br />
                      <i>
                        Simply the best…THAT’s{" "}
                        <span className="hstext">H&S</span>!
                      </i>{" "}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>

          <Row />

          <Tabs
            defaultActiveKey="1"
            transition={false}
            variant="pills"
            id="who-we-are-tabs"
            className="who-we-are-tabs"
          >
            <Tab eventKey="1" className="who-we-are-tabs" title="Our Story">
              <OurHistory />
            </Tab>
            <Tab eventKey="2" className="who-we-are-tabs" title="Core Values">
              <CoreValues />
            </Tab>
            {/* <Tab
              eventKey="3"
              className="who-we-are-tabs"
              title="Meet the Corporate Team"
            >
              <BiosCarousel />
            </Tab> */}
          </Tabs>

          <Row>
            <LandingBanner />
          </Row>
          {/* <ContactForm /> */}
        </div>
        <Footer />
        {this.state.showCovidModal && (
          <StyledModal
            show={this.state.showCovidModal}
            onHide={() => this.renderCovidModal()}
          >
            <Modal.Header closeButton>
              <Modal.Title>To Our Valued Customers:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <br />
              At <span className="hstext">H&S</span>, the health and safety of
              our customers and employees are our top priority. As we all
              navigate this environment of uncertainty regarding COVID-19,{" "}
              <span className="hstext">H&S</span> is taking every precaution
              recommended by the Centers for Disease Control (CDC) and the
              Arizona Department of Health to keep our team members safe and
              continue working to help keep your dialysis machines operating.
              <br />
              <br />
              As a medical service provider, we are continually monitoring the
              evolving situation and guidelines from public health organizations
              and federal, state and local governments. Our team is taking every
              step possible to remain open and are dedicated to not only meet,
              but exceed your expectations during this time. <br />
              <br />
              <br />
              As an additional measure, we have extended our hours of operation
              to better serve the dialysis community. We are now open for
              business Monday - Friday, from 6:00 am to 6:00 pm, Mountain
              Standard Time.
              <br />
              <br />
              We thank you for all you do to maintain vital dialysis treatments
              for patients everywhere.
              <br />
              <br />
              With Gratitude,
              <br />
              John C. Hyte
              <br /> President
              <br />
              <span className="hstext">H&S</span> Technical Services, Inc.
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => this.renderCovidModal()}
              >
                Close
              </Button>
            </Modal.Footer>
          </StyledModal>
        )}
      </div>
    );
  }
}

export default WhoWeAre;
